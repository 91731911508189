import { Controller } from "stimulus"

export default class extends Controller {
  save(event) {
    let element = event.target
    if (element.form !== undefined) {
      element.form.submit()
    } else {
      document.getElementById(event.target.getAttribute('input')).form.submit()
    }
  }
}
