// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
require("./richtext")
import "controllers"

const Sticky = require('sticky-js');
window.Noty = require("noty");

new Sticky('trix-toolbar');
new Sticky('.sticky');
document.addEventListener('turbo:visit', function () {
    new Sticky('trix-toolbar');
    new Sticky('.sticky');
})


document.addEventListener('turbo:load', function () {

    var $burger = document.querySelector('[data-menu-control]');
    if ($burger !== null) {
        var $menu = document.querySelector('.left-pane');
        $burger.addEventListener('click', function (event) {
            event.stopPropagation()
            $burger.classList.toggle('is-active');
            $menu.classList.toggle('is-hidden-mobile');
        });

        document.addEventListener('click', function (event) {
            $burger.classList.remove('is-active');
            $menu.classList.add('is-hidden-mobile');
        })
    }
});

// document.addEventListener('confirm:beforeSend', function (xhr, settings) {
//     alert('Hai');
// });